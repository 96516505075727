import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  /* padding: 2rem 0; */
  padding-top: 5rem;
  min-height: 740px;
  background-color: ${({ theme }) => (theme === "light" ? "#fff" : "#212121")};
`;

export const Grid = styled.div`
  z-index: 1;
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 2000px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem 3rem;
  }
`;

export const Item = styled.div`
  border-radius: 15px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

  h4 {
    color: ${({ theme }) => (theme === "light" ? "#212121" : "#fff")};
  }

  p {
    color: ${({ theme }) => (theme === "light" ? "#707070" : "#c7c7c7")};
  }
`;

export const Content = styled.div`
  padding: 1rem 0;
  min-height: 160px;
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    &:first-child {
      margin-right: 0.5rem;
    }

    img {
      margin: 0;
    }

    svg path {
      fill: ${({ theme }) => (theme === "light" ? "#000" : "#fff")};
    }

    span {
      color: ${({ theme }) => (theme === "light" ? "#000" : "#fff")};
      margin-left: 0.5rem;
    }
  }
`;

export const Languages = styled.div`
  opacity: 0.5;
  font-size: 14px;
`;

// Create the keyframes
const blob = keyframes`
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
`;

export const PinkBlob = styled.div`
  opacity: 0.3;
  z-index: 0;
  position: absolute;
  top: 110px;
  right: 20px;
  width: 20rem;
  height: 20rem;
  border-radius: 9999px;
  background: pink;
  mix-blend-mode: ${({ theme }) => (theme === "light" ? "multiply" : "unset")};
  filter: blur(30px);
  animation: ${blob} 7s infinite;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const BlueVioletBlob = styled.div`
  opacity: 0.4;
  z-index: 0;
  position: absolute;
  top: 60px;
  right: 100px;
  width: 20rem;
  height: 20rem;
  border-radius: 9999px;
  background: rgb(138, 43, 226);
  mix-blend-mode: ${({ theme }) => (theme === "light" ? "multiply" : "unset")};
  filter: blur(30px);
  animation: ${blob} 7s infinite;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const OrangeBlob = styled.div`
  opacity: 0.5;
  z-index: 0;
  position: absolute;
  top: 60px;
  right: 250px;
  width: 20rem;
  height: 20rem;
  border-radius: 9999px;
  background: orange;
  mix-blend-mode: ${({ theme }) => (theme === "light" ? "multiply" : "unset")};
  filter: blur(30px);
  animation: ${blob} 7s infinite;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  max-width: 1300px;
`;
