import styled from "styled-components";

export const Wrapper = styled.section`
   display: flex;
   flex-direction: column;
   vertical-align: middle;
   margin-top: 10rem;
   margin-bottom: 10rem;
   text-align: center;
`;

export const VideoDiv = styled.div`
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
   box-shadow: 3px 3px 10px 0px lightgray;

   @media (min-width: 993px) {
      width: 75%;
   }
`