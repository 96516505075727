import { Layout, Seo } from "components/common"
import { Contact, Intro, Projects, About } from "components/landing"
import { Footer } from "components/theme"
import { ThemeContext } from "providers/ThemeProvider"
import React, { forwardRef, useContext, useRef } from "react"
import { FlexibleLengthDiv } from "./styles"

const Overlay = forwardRef(({ caption, scroll }, ref) => {
  const { theme } = useContext(ThemeContext)

  const projectsRef = useRef()
  const aboutRef = useRef()
  const contactRef = useRef()

  return <Layout>
    <div
      ref={ref}
      onScroll={(e) => {
        let lowerEl = document.querySelector("div#projects");
        let bottomExtraDistance = e.target.scrollHeight - lowerEl.offsetTop
        let upperEl = document.querySelector("div#about");
        scroll.current = (e.target.scrollTop - upperEl.offsetTop - upperEl.clientHeight) / (e.target.scrollHeight - window.innerHeight - bottomExtraDistance - upperEl.offsetTop - upperEl.clientHeight) * 0.6
        if (scroll.current < 0) {
          scroll.current = 0
        }
        if (scroll.current > 1) {
          scroll.current = 1
        }
        // caption.current.innerText = scroll.current.toFixed(2)
        if (parseFloat(scroll.current) <= 0.6 && parseFloat(scroll.current) > 0) {
          caption.current.style.display = 'block'
        } else {
          caption.current.style.display = 'none'
        }
      }}
      className="scroll"
    >
      <Seo />
      <Intro projectsRef={projectsRef} contactRef={contactRef} aboutRef={aboutRef} />
      <About ref={aboutRef} contactRef={contactRef} />
      <FlexibleLengthDiv>
        <div className="dot">
          <h1 style={{ color: theme === 'light' ? 'hsla(0, 0%, 0%, 0.8)' : '#fff' }}>innovative</h1>
          {/* Virtual reality (VR) is a simulated experience that can be similar to or completely different from the real world. */}
          I love innovative ideas. I always explore projects and playing around in the open-source communities.
          It is inspiring and motivates me to create my own new things.
        </div>
      </FlexibleLengthDiv>
      <div style={{ height: "200vh" }}>
        <div className="dot">
          <h1 style={{ color: theme === 'light' ? 'hsla(0, 0%, 0%, 0.8)' : '#fff' }}>strategic</h1>
          I am good at formulating strategies and choose appropriate course of action for the realization of my objectives and thereby achieving my vision.
        </div>
      </div>
      <div style={{ height: "200vh" }}>
        <div className="dot">
          <h1 style={{ color: theme === 'light' ? 'hsla(0, 0%, 0%, 0.8)' : '#fff' }}>dream</h1>
          I am a dreamer. I have many small tiny dreams. I believe that if all dreams come to together, they become a big significant one.
        </div>
      </div>
      {/* <div style={{ height: "200vh" }}>
        <div className="dot">
          <h1 style={{ color: theme === 'light' ? 'hsla(0, 0%, 0%, 0.8)' : '#fff' }}></h1>
          A turbine (/ˈtɜːrbaɪn/ or /ˈtɜːrbɪn/) (from the Greek τύρβη, tyrbē, or Latin turbo, meaning vortex)[1][2] is a
          rotary mechanical device that extracts energy from a fluid flow and converts it into useful work.
        </div>
      </div> */}
      {/* <div style={{ height: "200vh" }}>
        <div className="dot">
          <h1 style={{ color: theme === 'light' ? 'hsla(0, 0%, 0%, 0.8)' : '#fff' }}></h1>
          A table is an item of furniture with a flat top and one or more legs, used as a surface for working at, eating from or
          on which to place things.[1][2]
        </div>
      </div> */}
      <div style={{ height: "200vh" }}>
        <div className="dot">
          <h1 style={{ color: theme === 'light' ? 'hsla(0, 0%, 0%, 0.8)' : '#fff' }}>develop</h1>
          I primarily enjoy developing software, delivering high performance ones, with great user experience that delight everyone.
        </div>
      </div>
      {/* <div style={{ height: "200vh" }}>
        <div className="dot">
          <h1 style={{ color: theme === 'light' ? 'hsla(0, 0%, 0%, 0.8)' : '#fff' }}></h1>
          A Zeppelin is a type of rigid airship named after the German inventor Count Ferdinand von Zeppelin (German
          pronunciation: [ˈt͡sɛpəliːn]) who pioneered rigid airship development at the beginning of the 20th century.
        </div>
      </div> */}
      <Projects ref={projectsRef} />
      <Contact ref={contactRef} />
      <Footer />
      {/* <span className="caption" ref={caption}>
        0.00
      </span> */}
      <span className="credit-caption" ref={caption}>
        Credit to <a href="https://github.com/drcmda" target="_blank" rel="noopener noreferrer">drcmda</a>, powered by <a href="https://github.com/pmndrs/drei" target="_blank" rel="noopener noreferrer">@react-three/drei©</a>
      </span>
    </div>
  </Layout>
})

export default Overlay
