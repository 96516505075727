import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import ToggleTheme from 'components/theme/Header/ToggleTheme';
import { Wrapper } from './styles';

const NavbarLinks = ({ desktop, projectsRef, contactRef, aboutRef }) => {
  const { theme } = useContext(ThemeContext);

  const scrollToEl = (ref) => {
    ref.current.scrollIntoView()
  }

  return (
    <Wrapper desktop={desktop} theme={theme}>
      <a href="/" onClick={(e) => { e.preventDefault(); scrollToEl(aboutRef) }}>About</a>
      <a href="/" onClick={(e) => { e.preventDefault(); scrollToEl(projectsRef) }}>Projects</a>
      <a href="/" onClick={(e) => { e.preventDefault(); scrollToEl(contactRef) }}>Contact</a>
      <ToggleTheme />
    </Wrapper>
  )

};

export default NavbarLinks;
