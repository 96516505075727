/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useEffect, useRef, useState, useMemo } from "react"
import { useGLTF, useAnimations, PerspectiveCamera } from "@react-three/drei"
import { extend, useFrame } from "@react-three/fiber"
import { EffectComposer, ShaderPass, RenderPass } from 'three-stdlib'

extend({ EffectComposer, ShaderPass, RenderPass })

const color = new THREE.Color()

export const DEFAULT_LAYER = 0
export const OCCLUSION_LAYER = 1

const getColorNames = (name) => {
  // switch (name) {
  //   case "Notebook":
  //     return "#505050";
  //   case "Rocket003":
  //     return "tan";
  //   case "VR_Headset":
  //     return "thistle";
  //   default:
  //     return "white";
  // }
  return "gray";
}

export default function Model({ layer = DEFAULT_LAYER, scroll, ...props }) {

  const t = useRef(0)
  const group = useRef()
  const { nodes, materials, animations } = useGLTF("/model.glb")
  const { nodes: archNodes } = useGLTF('/archer.glb')
  const { actions, mixer } = useAnimations(animations, group)
  const [hovered, set] = useState()
  const extras = { layers: layer, receiveShadow: true, castShadow: true, "material-envMapIntensity": 0.2 }
  const materialProps = { roughness: .4, metalness: .8 }
  useEffect(() => void actions["CameraAction.005"].play(), [actions])
  useEffect(() => {
    if (hovered)
      group.current.getObjectByName(hovered).material.color.set("#7f76fd")
    else if (hovered === '') {
      group.current.children[0].children.filter(x => x.name === 'arch')[0].children.forEach((child, i) => {
        child.material.color.set("#7f76fd")
      })
    }
    document.body.style.cursor = hovered ? "pointer" : "auto"
  }, [hovered])
  useFrame((state) => {
    mixer.setTime((t.current = THREE.MathUtils.lerp(t.current, actions["CameraAction.005"]._clip.duration * scroll.current, 0.05)))
    group.current.children[0].children.forEach((child, index) => {
      if (child.children.length > 0) {
        child.children.forEach((subChild, i) => {
          subChild.material.color.lerp(color.set(hovered === subChild.name ? "#847efc" : layer === DEFAULT_LAYER ? "#505050" : "gray").convertSRGBToLinear(), hovered ? 0.1 : 0.05)
        })
      } else {
        child.material.color.lerp(color.set(hovered === child.name ? "#847efc" : layer === DEFAULT_LAYER ? "#505050" : getColorNames(child.name)).convertSRGBToLinear(), hovered ? 0.1 : 0.05)
      }

      const et = state.clock.elapsedTime
      child.position.y = Math.sin((et + index * 2000) / 2) * 1
      child.rotation.x = Math.sin((et + index * 2000) / 3) / (child.name === 'arch' ? 30 : 10)
      child.rotation.y = Math.cos((et + index * 2000) / 2) / (child.name === 'arch' ? 30 : 10)
      child.rotation.z = Math.sin((et + index * 2000) / 3) / (child.name === 'arch' ? 30 : 10)

      if (child.name === 'Notebook') {
        child.position.x = 15
        child.position.y += -14
        child.position.z = 14

        child.rotation.x += 0
        child.rotation.y += -1.1
        child.rotation.z += 0
      }

    })
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group
        onPointerOver={(e) => { e.stopPropagation(); set(e.object.name) }}
        onPointerOut={(e) => { e.stopPropagation(); set(null) }}
        position={[0.06, 4.04, 0.35]}
        scale={[0.25, 0.25, 0.25]}>
        {/* <mesh name="Headphones" geometry={nodes.Headphones.geometry} material={materials.M_Headphone} {...extras} /> */}
        <mesh
          name="Notebook"
          geometry={nodes.Notebook.geometry}
          material={materials.M_Notebook}
          {...extras}
          position={[15, -14, 14]}
          rotation={[0, -1.1, 0]}
        >
          <meshStandardMaterial {...materialProps} />
        </mesh>
        <mesh name="Rocket003" geometry={nodes.Rocket003.geometry} material={materials.M_Rocket} {...extras}>
          <meshStandardMaterial {...materialProps} />
        </mesh>
        <group name="arch">
          <mesh
            // name="arch2"
            layers={layer}
            position={[50, 7, 15]}
            scale={[1.5, 1.5, 1.5]}
            rotation={[4.8, 0, 1]}
            castShadow
            receiveShadow
            geometry={archNodes.mesh_2.geometry}
          // material={archNodes.mesh_2.material}
          >
            <meshStandardMaterial {...materialProps} />
          </mesh>
          <mesh
            // name="arch0"
            layers={layer}
            position={[50, 7, 15]}
            scale={[1.5, 1.5, 1.5]}
            rotation={[4.8, 0, 1]}
            castShadow
            receiveShadow
            geometry={archNodes.mesh_0.geometry}
          // material={archNodes.mesh_0.material}
          >
            <meshStandardMaterial {...materialProps} />
          </mesh>
          <mesh
            // name="arch"
            layers={layer}
            position={[50, 7, 15]}
            scale={[1.5, 1.5, 1.5]}
            rotation={[4.8, 0, 1]}
            castShadow
            receiveShadow
            geometry={archNodes.mesh_1.geometry}
          // material={archNodes.mesh_1.material}
          >
            <meshStandardMaterial {...materialProps} />
          </mesh>
        </group>
        {/* <mesh name="Roundcube001" geometry={nodes.Roundcube001.geometry} material={materials.M_Roundcube} {...extras}>
          <meshStandardMaterial {...materialProps} />
        </mesh> */}
        {/* <mesh name="Table" geometry={nodes.Table.geometry} material={materials.M_Table} {...extras}>
          <meshStandardMaterial {...materialProps} />
        </mesh> */}
        <mesh name="VR_Headset" geometry={nodes.VR_Headset.geometry} material={materials.M_Headset} {...extras}>
          <meshStandardMaterial {...materialProps} />
        </mesh>
        {/* <mesh
          name="Zeppelin"
          geometry={nodes.Zeppelin.geometry}
          material={materials.M_Zeppelin}
          {...extras}
          position={[-30, 0, 6]}
          rotation={[-2.3, -1, -1.6]}
        >
          <meshStandardMaterial {...materialProps} />
        </mesh> */}
      </group>
      <group name="Camera" position={[-1.78, 2.04, 23.58]} rotation={[1.62, 0.01, 0.11]}>
        <PerspectiveCamera makeDefault far={100} near={0.1} fov={28} rotation={[-Math.PI / 2, 0, 0]}>
          <directionalLight
            castShadow
            position={[10, 20, 15]}
            shadow-camera-right={8}
            shadow-camera-top={8}
            shadow-camera-left={-8}
            shadow-camera-bottom={-8}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            intensity={2}
            shadow-bias={-0.0001}
          />
        </PerspectiveCamera>
      </group>
    </group>
  )
}

useGLTF.preload("/model.glb")
useGLTF.preload('/archer.glb')