import React, { Suspense, useContext, useRef } from "react"
import { Canvas } from "@react-three/fiber"
import { Html, Environment } from "@react-three/drei"
import Model, { OCCLUSION_LAYER } from "components/threejs/Model"
import Overlay from "components/threejs/Overlay"
// import * as THREE from 'three'
import "../styles.css"
import EffectGroup from "components/threejs/Effects/EffectGroup"
import { ThemeContext } from "providers/ThemeProvider"

const Home = () => {

	const { theme } = useContext(ThemeContext)

	const overlay = useRef()
	const caption = useRef()
	const scroll = useRef(0)

	return <>
		<Canvas
			style={{ height: '100vh', zIndex: -1 }}
			shadows
			onCreated={(state) => state.events.connect(overlay.current)}
			raycaster={{ computeOffsets: ({ clientX, clientY }) => ({ offsetX: clientX, offsetY: clientY }) }}>
			<ambientLight intensity={1} />
			<Suspense fallback={<Html center className="loading" children="Loading..."></Html>}>
				<Model scroll={scroll} />
				<Model layer={OCCLUSION_LAYER} scroll={scroll} />
				<Environment preset="city" />
				{/* <Stage adjustCamera={false} environment="city" /> */}
				{/* <Effects ref={occlusionRenderTarget} disableGamma={true} disableRenderPass={false}>
					<shaderPass attachArray="passes" args={[VolumetricLightShader]} needsSwap={false} />
				</Effects>
				<Effects disableGamma={true} disableRenderPass={false}>
					<shaderPass attachArray="passes" args={[AdditiveBlendingShader]} uniforms-tAdd-value={occlusionRenderTarget.texture} />
				</Effects> */}
				<EffectGroup theme={theme} />
			</Suspense>
		</Canvas>
		<Overlay ref={overlay} caption={caption} scroll={scroll} />
	</>
};

export default Home;
