import React, { useRef, useEffect, useMemo } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import VolumetricLightShader from "../Shaders/VolumetricLightShader"
import AdditiveBlendingShader from "../Shaders/AdditiveBlendingShader"
import { DEFAULT_LAYER, OCCLUSION_LAYER } from "../Model"
import * as THREE from 'three'
import { FXAAShader } from "three-stdlib"

export default function EffectGroup({ theme }) {

   const occlusionRenderTarget = useMemo(() => new THREE.WebGLRenderTarget(), [])

   const occlusionComposer = useRef()
   const composer = useRef()
   const { gl, camera, scene, size } = useThree()

   useEffect(() => {
      occlusionComposer.current.setSize(size.width, size.height)
      composer.current.setSize(size.width, size.height)
   }, [size])

   useFrame(() => {
      camera.layers.set(OCCLUSION_LAYER)
      occlusionComposer.current.render()
      camera.layers.set(DEFAULT_LAYER)
      composer.current.render()
   }, 1)

   return <>
      {/* {theme !== 'light' && <mesh layers={OCCLUSION_LAYER} position={[3, 8, 1]}>
         <sphereBufferGeometry args={[2, 32, 32]} />
         <meshBasicMaterial />
      </mesh>} */}
      <effectComposer ref={occlusionComposer} args={[gl, occlusionRenderTarget]} renderToScreen={false}>
         <renderPass attachArray="passes" scene={scene} camera={camera} />
         <shaderPass attachArray="passes" args={[VolumetricLightShader]} needsSwap={false} />
      </effectComposer>
      <effectComposer ref={composer} args={[gl]}>
         <renderPass attachArray="passes" scene={scene} camera={camera} />
         <shaderPass attachArray="passes" args={[AdditiveBlendingShader]} uniforms-tAdd-value={occlusionRenderTarget.texture} />
         <shaderPass attachArray="passes" args={[FXAAShader]} uniforms-resolution-value={[1 / size.width, 1 / size.height]} renderToScreen />
      </effectComposer>
   </>
}