import React from "react";
import { Container } from "components/common";
import { Wrapper, Flex, Links, Details } from "./styles";
import social from "./social.json";
import { useContext } from "react";
import { ThemeContext } from "providers/ThemeProvider";

export const Footer = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <Wrapper
      style={{ backgroundColor: theme === "light" ? "#fff" : "#212121" }}
    >
      <Flex as={Container} style={{ maxWidth: 1300 }}>
        <Details>
          <h2>Tinsley Lau</h2>
          <span>
            © All rights are reserved | {new Date().getFullYear()} | Made with{" "}
            <span aria-label="love" role="img">
              💖
            </span>{" "}
            by{" "}
            <a
              href="https://www.linkedin.com/in/tinsley-lau-655448134/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Tinsley
            </a>
          </span>
        </Details>
        <Links>
          {social.map(({ id, name, link, icon }) => (
            <a
              key={id}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`follow me on ${name}`}
            >
              <img width="24" src={icon} alt={name} />
            </a>
          ))}
        </Links>
      </Flex>
    </Wrapper>
  );
};
