import React, { useContext, forwardRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ThemeContext } from "providers/ThemeProvider";
import { Container, Card, TitleWrap } from "components/common";
import Star from "components/common/Icons/Star";
import Fork from "components/common/Icons/Fork";
import {
  Wrapper,
  Grid,
  Item,
  Content,
  Stats,
  Languages,
  PinkBlob,
  OrangeBlob,
  BlueVioletBlob,
  InnerWrapper,
} from "./styles";

export const Projects = forwardRef(({}, ref) => {
  const { theme } = useContext(ThemeContext);
  const {
    github: {
      viewer: {
        repositories: { edges },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        github {
          viewer {
            repositories(
              first: 8
              orderBy: { field: STARGAZERS, direction: DESC }
            ) {
              edges {
                node {
                  id
                  name
                  url
                  description
                  stargazers {
                    totalCount
                  }
                  forkCount
                  languages(first: 3) {
                    nodes {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  // const edges = [
  //   {
  //     node: {
  //       id: "0",
  //       url: "",
  //       name: "lybc-web",
  //       description: "A LYBC Website",
  //       stargazers: { totalCount: 0 },
  //       forkCount: 0,
  //       languages: {
  //         nodes: [
  //           { id: 0, name: "HTML" },
  //           { id: 1, name: "TypeScript" },
  //         ],
  //       },
  //     },
  //   },
  //   {
  //     node: {
  //       id: "1",
  //       url: "",
  //       name: "cryptoBact",
  //       description: "A Ethereum blockchain game",
  //       stargazers: { totalCount: 0 },
  //       forkCount: 0,
  //       languages: {
  //         nodes: [
  //           { id: 0, name: "JavaScript" },
  //           { id: 1, name: "python" },
  //           { id: 2, name: "C#" },
  //         ],
  //       },
  //     },
  //   },
  // ];

  return (
    <Wrapper theme={theme} ref={ref} as={Container} id="projects">
      <InnerWrapper>
        <h2>Projects</h2>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", position: "relative" }}>
            <BlueVioletBlob theme={theme} />
            <OrangeBlob theme={theme} />
            <PinkBlob theme={theme} />
            <Grid>
              {edges.map(({ node }) => (
                <Item
                  key={node.id}
                  as="a"
                  href={node.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  theme={theme}
                >
                  <Card theme={theme}>
                    <Content>
                      <h4>{node.name}</h4>
                      <p>{node.description}</p>
                    </Content>
                    <TitleWrap>
                      <Stats theme={theme}>
                        <div>
                          <Star color={theme === "light" ? "#000" : "#fff"} />
                          <span>{node.stargazers.totalCount}</span>
                        </div>
                        <div>
                          <Fork color={theme === "light" ? "#000" : "#fff"} />
                          <span>{node.forkCount}</span>
                        </div>
                      </Stats>
                      <Stats theme={theme}>
                        <Languages>
                          {node.languages.nodes.map(({ id, name }) => (
                            <span key={id}>{name}</span>
                          ))}
                        </Languages>
                      </Stats>
                    </TitleWrap>
                  </Card>
                </Item>
              ))}
            </Grid>
          </div>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
});
