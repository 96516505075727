import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import dev from 'assets/avatars/avatar.jpg';
import { Wrapper, IntroWrapper, Details, Thumbnail, Avatar } from './styles';

export const Intro = ({ projectsRef, contactRef, aboutRef }) => {
  // const available = useRef(false)
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper theme={theme}>
      <Header projectsRef={projectsRef} contactRef={contactRef} aboutRef={aboutRef} />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          <h1>Hi There!</h1>
          <h4>I’m Tinsley and I’m a software engineer!</h4>
          <Button as="a" href="/" onClick={(e) => {
            e.preventDefault();
            contactRef.current.scrollIntoView();
          }}>
            Get in touch
          </Button>
        </Details>
        <Thumbnail>
          <Avatar src={dev} alt="I’m Tinsley and I’m a software engineer!" />
          {/* {available.current ? <BadgeCard>
            <GreenLightDot />
            <span style={{ marginLeft: 'auto', marginRight: 'auto' }}>Open to market 🎯</span>
          </BadgeCard>
            :
            <BadgeCard>
              <YellowLightDot />
              <span style={{ marginLeft: 'auto', marginRight: 'auto' }}>Software Engineer@HongkongPost</span>
            </BadgeCard>} */}
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
