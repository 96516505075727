import styled from "styled-components";
import overlayIllustration from "assets/illustrations/overlay.svg";

export const Wrapper = styled.div`
  padding-bottom: 4rem;
  min-height: 800px;
  background-image: url(${overlayIllustration});
  background-color: ${({ theme }) => (theme === "light" ? "#fff" : "#212121")};
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
`;

export const IntroWrapper = styled.div`
  max-width: 1024px;
  padding-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 36pt;
    color: ${({ theme }) => (theme === "light" ? "#212121" : "#fff")};

    @media (max-width: 1200px) {
      mix-blend-mode: ${({ theme }) =>
        theme === "light" ? "unset" : "difference"};
    }

    @media (max-width: 680px) {
      font-size: 30pt;
    }
  }

  h4 {
    margin-bottom: 2.5rem;
    font-size: 32pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === "light" ? "#707070" : "#e6e6e6")};
    mix-blend-mode: ${({ theme }) =>
      theme === "light" ? "unset" : "difference"};

    /* @media (max-width: 960px) {
    } */

    @media (max-width: 680px) {
      font-size: 26pt;
    }
  }
`;

export const Thumbnail = styled.div`
  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;

export const Avatar = styled.img`
  box-shadow: 5px 5px 10px lightgray;

  border-radius: 9999px;

  width: 24rem !important;
  height: auto;

  @media (max-width: 960px) {
    width: 18rem !important;
    height: 18rem;
  }
`;
