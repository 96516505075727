import React, { forwardRef } from "react";
import { Container } from "components/common";
import contact from "assets/illustrations/contact.svg";
import { Wrapper, Details, Thumbnail, InnerWrapper } from "./styles";
import ContactForm from "./ContactForm";
import { useContext } from "react";
import { ThemeContext } from "providers/ThemeProvider";

export const Contact = forwardRef(({}, ref) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Wrapper
      ref={ref}
      style={{ backgroundColor: theme === "light" ? "#fff" : "#212121" }}
      as={Container}
      id="contact"
    >
      <InnerWrapper>
        <Details>
          <h1
            style={{
              color: theme === "light" ? "hsla(0, 0%, 0%, 0.8)" : "#fff",
            }}
          >
            Get In Touch
          </h1>
          <ContactForm />
        </Details>
        <Thumbnail>
          <img src={contact} alt="I’m Tinsley and I’m a software engineer!" />
        </Thumbnail>
      </InnerWrapper>
    </Wrapper>
  );
});
