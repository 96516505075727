import React, { forwardRef, useContext } from "react";
import { ThemeContext } from "providers/ThemeProvider";
import { Container, Button } from "components/common";
import dev from "assets/illustrations/skills.svg";
import {
  Wrapper,
  SkillsWrapper,
  Details,
  Thumbnail,
  InnerWrapper,
} from "./styles";

export const About = forwardRef(({ contactRef }, ref) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper ref={ref} id="about" theme={theme}>
      <InnerWrapper>
        <SkillsWrapper as={Container}>
          <Thumbnail>
            <img src={dev} alt="I’m Tinsley and I’m a software engineer!" />
          </Thumbnail>
          <Details theme={theme}>
            <h1>About myself</h1>
            <p>
              I'm a software engineer with knowledge and experience in a wide
              spectrum of technologies. I'm always ready to delivery solutions.
              <br />
              I'm also a good team player who enjoys sharing ideas and brainstorming with the others.
            </p>
            <Button
              as="a"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                contactRef.current.scrollIntoView();
              }}
            >
              Get in touch
            </Button>
          </Details>
        </SkillsWrapper>
      </InnerWrapper>
    </Wrapper>
  );
});
