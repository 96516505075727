import styled from "styled-components";

export const FlexibleLengthDiv = styled.div`
  height: 425vh;

  @media (min-width: 601px){
    height: 350vh;
  }

  @media (min-width: 1300px){
    height: 400vh;
  }
`